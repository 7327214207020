import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Header from "../../components/header";
import { PuffLoader } from "react-spinners";
import "./styles.css";

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProduct = async () => {
      try {
        setLoading(true);

        const response = await axios.post(
          "https://test-server-five-weld.vercel.app/api/gost/get-product",
          { id }
        );

        setProduct(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    getProduct();
  }, []);

  return (
    <div className="page-container">
      <Header />

      {loading === true ? (
        <PuffLoader />
      ) : (
        <div className="product-page-content">
          <div className="product-content-div">
            <img className="product-page-img" src={product?.slika?.url} />

            <div className="product-text-div">
              <h1 className="product-page-h">{product?.ime}</h1>
              <div className="product-brand-div">
                <p className="product-brand-p">
                  Brand:{" "}
                  <span className="product-brand-span">{product?.brand}</span>
                </p>
                <div className="product-brand-line" />
                <p className="product-brand-p">
                  Šifra:{" "}
                  <span className="product-brand-span">{product?.sifra}</span>
                </p>
              </div>

              <h3 className="product-page-price">
                {product?.cijena?.toFixed(2)}€
              </h3>

              <div className="product-brand-div">
                <div
                  className={`product-dot ${
                    product?.dostupnost ? "dot-green" : "dot-red"
                  }`}
                />
                <p
                  className={`product-dostupnost-p ${
                    product?.dostupnost ? "color-green" : "color-red"
                  }`}
                >
                  {product?.dostupnost ? "Na stanju" : "Nema na stanju"}
                </p>
              </div>
            </div>
          </div>
          <div className="product-opis-div">
            <h3 className="product-opis-h">Opis</h3>

            <div className="product-opis-line" />

            <p className="product-opis-p">
              {product?.opis ? product?.opis : "Ovaj artikal nema opis."}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
