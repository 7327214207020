import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import axios from "axios";
import Header from "../../components/header";
import { Link } from "react-router-dom";
import "./styles.css";

const Brand = () => {
  const { brand } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      try {
        setLoading(true);

        const response = await axios.post(
          "https://test-server-five-weld.vercel.app/api/gost/get-products",
          { brand }
        );

        setProducts(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    getProducts();
  }, []);

  return (
    <div className="page-container">
      <Header />

      {loading === true ? (
        <PuffLoader size={50} color={"#031623"} />
      ) : (
        <div className="artikli-div">
          {products?.map((product) => {
            return (
              <div
                key={product.id}
                className="product-card-div animate__animated animate__fadeInUp animate__faster"
              >
                <img className="product-card-img" src={product.slika.url} />
                <h2 className="product-card-name">{product.ime}</h2>
                <p className="product-card-id">{product.sifra}</p>
                <h3 className="product-card-price">
                  {product.cijena.toFixed(2)}€
                </h3>
                <div className="product-card-status-div">
                  <div
                    className={`product-card-dot ${
                      product.dostupnost === true ? "dot-green" : "dot-red"
                    }`}
                  />
                  <p
                    className={`product-card-status-p ${
                      product.dostupnost === true ? "color-green" : "color-red"
                    }`}
                  >
                    {product.dostupnost === true
                      ? "Na stanju"
                      : "Nema na stanju"}
                  </p>
                </div>

                <Link
                  className="product-card-btn"
                  to={`/${product.brand}/${product.id}`}
                >
                  <div className="">Pogledaj artikal</div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Brand;
