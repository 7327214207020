import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

import notFoundImg from "../../assets/404.png";
import Header from "../../components/header";

const NotFound = () => {
  return (
    <div className="page-container">
      <Header />
      <img className="not-found-img" src={notFoundImg} />

      <Link to={"/"} className="product-link">
        <div className="not-found-btn">Nazad na početnu</div>
      </Link>
    </div>
  );
};

export default NotFound;
