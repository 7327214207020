import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import "./styles.css";

import { PuffLoader } from "react-spinners";
import { Link } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBrands = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          "https://test-server-five-weld.vercel.app/api/gost/get-brands"
        );

        console.log(response);

        setBrands(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    getBrands();
  }, []);

  return (
    <div className="page-container">
      <Header />

      {loading === true ? (
        <PuffLoader size={50} color={"#031623"} />
      ) : (
        <div className="brands-div">
          {brands?.map((brand) => {
            return (
              <Link key={brand.identifier} to={`/${brand.ime}`}>
                <div className="brand-div animate__animated animate__fadeInUp animate__faster">
                  <img className="brand-img" src={brand.url} />
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Home;
