import React from "react";
import "./styles.css";

import logo from "../../assets/dg_logo.png";

const Header = () => {
  return (
    <div className="header-container">
      <img className="header-logo" src={logo} />
    </div>
  );
};

export default Header;
