import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";

import Home from "./pages/home";
import Brand from "./pages/brand";
import Product from "./pages/product";
import NotFound from "./pages/404";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:brand" element={<Brand />} />
        <Route path="/:brand/:id" element={<Product />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
